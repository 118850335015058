import {
  FETCH_SERVICIOS_REQUEST,
  FETCH_SERVICIOS_SUCCESS,
  FETCH_SERVICIOS_ERROR,
  SET_CONTRAIBLE_EXPANDED,
  SET_CONTRAIBLE_CHILD_EXPANDED,
  SET_ACORDION_SERVICIO,
  SET_TIPO_SERVICIO_TURNO_SEL,
  SET_TIPO_SERVICIO_AYUDAS,
} from '../actions';

export const fetchServiciosRequest = (codMutual, cuil) => ({
  type: FETCH_SERVICIOS_REQUEST,
  payload: { codMutual, cuil },
});
export const fecthServiciosSuccess = (servicios) => ({
  type: FETCH_SERVICIOS_SUCCESS,
  payload: servicios.data,
});
export const fectchServiciosError = (message) => ({
  type: FETCH_SERVICIOS_ERROR,
  payload: { message },
});
export const setContraibleExpanded = (contraibleId) => ({
  type: SET_CONTRAIBLE_EXPANDED,
  payload: contraibleId,
});
export const setContraibleChildExpanded = (contraibleId) => ({
  type: SET_CONTRAIBLE_CHILD_EXPANDED,
  payload: contraibleId,
});

export const setAcordionServicio = (objServicioSel) => ({
  type: SET_ACORDION_SERVICIO,
  payload: objServicioSel,
});

export const setTipoServicioTurnoSel = (tipoServicioSel) => ({
  type: SET_TIPO_SERVICIO_TURNO_SEL,
  payload: tipoServicioSel,
});
export const setTipoServicioAyudas = (tipoServicioAyudas) => ({
  type: SET_TIPO_SERVICIO_AYUDAS,
  payload: tipoServicioAyudas,
});
