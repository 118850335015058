import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import notificacionesSagas from './notificaciones/saga';
import serviciosSagas from './servicios-socio/saga';
import AyudaSagas from './ayuda/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    notificacionesSagas(),
    serviciosSagas(),
    AyudaSagas(),
  ]);
}
