import {
  FETCH_SERVICIOS_REQUEST,
  FETCH_SERVICIOS_SUCCESS,
  FETCH_SERVICIOS_ERROR,
  SET_CONTRAIBLE_EXPANDED,
  SET_CONTRAIBLE_CHILD_EXPANDED,
  SET_ACORDION_SERVICIO,
  SET_TIPO_SERVICIO_TURNO_SEL,
  SET_TIPO_SERVICIO_AYUDAS,
} from '../actions';

const INIT_STATE = {
  servicios: [],
  saldo_ca: null,
  saldo_ca_pen: null,
  loading: false,
  error: null,
  contraibleIdExpanded: null,
  contraibleIdChildExpanded: null,
  acordionServicios: { grupoServicio: null, servicio: null },
  tipoServicioTurnoSel: null,
  tipoServicioAyudas: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICIOS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SERVICIOS_SUCCESS: {
      const { out_parameters: outParameters } = action.payload;
      const { cursor_saldos: cursorSaldos } = outParameters || [];
      const { SALDO_CA, SALDO_CA_PEN } = cursorSaldos[0];
      return {
        ...state,
        loading: false,
        saldo_ca: SALDO_CA,
        saldo_ca_pen: SALDO_CA_PEN,
        servicios: action.payload.data,
      };
    }
    case FETCH_SERVICIOS_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case SET_CONTRAIBLE_EXPANDED:
      return {
        ...state,
        contraibleIdExpanded: action.payload,
      };

    case SET_CONTRAIBLE_CHILD_EXPANDED:
      return {
        ...state,
        contraibleIdChildExpanded: action.payload,
      };

    case SET_ACORDION_SERVICIO:
      return {
        ...state,
        acordionServicios: action.payload,
      };

    case SET_TIPO_SERVICIO_TURNO_SEL:
      return {
        ...state,
        tipoServicioTurnoSel: action.payload,
      };
    case SET_TIPO_SERVICIO_AYUDAS:
      return {
        ...state,
        tipoServicioAyudas: action.payload,
      };

    default:
      return state;
  }
};
