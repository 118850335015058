import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { persistence } from '../../services';
import { getErrorOject } from '../../helpers/fetch-error';

import { FETCH_SERVICIOS_REQUEST } from '../actions';

import { fecthServiciosSuccess, fectchServiciosError } from './actions';

const getServiciosAsync = async (codMutual, cuil) =>
  persistence
    .fetch('asociados', 'serv_socio', { cuil, cod_mutual: codMutual }, false)
    .then((serv) => serv)
    .catch((error) => getErrorOject(error));

function* getServicios({ payload }) {
  const { codMutual, cuil } = payload;
  try {
    const servicios = yield call(getServiciosAsync, codMutual, cuil);
    if (!servicios.errorMessage) {
      yield put(fecthServiciosSuccess(servicios));
    } else {
      yield put(fectchServiciosError(servicios.errorMessage));
    }
  } catch (error) {
    yield put(fectchServiciosError(error.message));
  }
}

export function* watchFetchServiciosRequest() {
  yield takeEvery(FETCH_SERVICIOS_REQUEST, getServicios);
}

export default function* rootSaga() {
  yield all([fork(watchFetchServiciosRequest)]);
}
