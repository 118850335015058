import axios from 'axios';
import { persistence } from './persistence';
import { getErrorMessage } from '../helpers/fetch-error';

const callPagar = (paramsValues) =>
  persistence
    .fetch('PAGOS', 'PAGAR_CON_CA', paramsValues)
    .then((e) => {
      return e.data.data.length > 0
        ? { data: e.data.data[0], out_parameters: e.data.out_parameters }
        : {
            ERROR:
              'No se pudo procesar el pago, por favor volvé a intentar más tarde.-',
          };
    })
    .catch((error) => {
      return { ERROR: getErrorMessage(error) };
    });

const callPagarMP = (paramsValues) =>
  persistence
    .fetch('PAGOS', 'generar_ingreso_pend_mp', paramsValues)
    .then((e) => {
      return e.data.data.length > 0
        ? { data: e.data.data[0], out_parameters: e.data.out_parameters }
        : {
            ERROR:
              'No se pudo procesar el pago, por favor volvé a intentar más tarde.-',
          };
    })
    .catch((error) => {
      return { ERROR: getErrorMessage(error) };
    });

function createPreferenceMP(params) {
  return axios.get('/mp/create-preference', { params });
}

export const pagosService = {
  callPagar,
  callPagarMP,
  createPreferenceMP,
};

export default pagosService;
