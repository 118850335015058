import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import notificaciones from './notificaciones/reducer';
import serviciosSocio from './servicios-socio/reducer';
import ayuda from './ayuda/reducer';
import digitalSignature from './digital-signature/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  notificaciones,
  serviciosSocio,
  ayuda,
  digitalSignature,
});

export default reducers;
