/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const CONNECT_USER = 'CONNECT_USER';
export const CONNECT_USER_SUCCESS = 'CONNECT_USER_SUCCESS';
export const CONNECT_USER_ERROR = 'CONNECT_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const HIDE_MODAL_NOTIF = 'HIDE_MODAL_NOTIF';
export const CHANGE_USER = 'CHANGE_USER';
export const LOGIN_USER_CUIL_PASSWORD = 'LOGIN_USER_CUIL_PASSWORD';

/* AYUDAS */
export const GET_GRUPO_AYUDA_REQUEST = 'GET_GRUPO_AYUDA_REQUEST';
export const FETCH_GRUPO_AYUDA_SUCCESS = 'FETCH_GRUPO_AYUDA_SUCCESS';
export const FETCH_GRUPO_AYUDA_ERROR = 'FETCH_GRUPO_AYUDA_ERROR';
export const FETCH_AGREGAR_AYUDA_REQUEST = 'FETCH_AGREGAR_AYUDA_REQUEST';
export const FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST =
  'FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST';
export const FETCH_AGREGAR_AYUDA_SUCCESS = 'FETCH_AGREGAR_AYUDA_SUCCESS';
export const FETCH_AGREGAR_AYUDA_ERROR = 'FETCH_AGREGAR_AYUDA_ERROR';
export const SET_MONTO_SOLICITADO = 'SET_MONTO_SOLICITADO';
export const SET_SOL_MARGEN_ID = 'SET_SOL_MARGEN_ID';
export const SET_OBS_DOCUMENTOS = 'SET_OBS_DOCUMENTOS';
export const SET_PLAN_SELECTED = 'SET_PLAN_SELECTED';
export const SET_IS_STEP_VALIDO = 'SET_IS_STEP_VALIDO';
export const SET_IS_VALIDAR_STEP = 'SET_IS_VALIDAR_STEP';
export const SET_TELEFONO = 'SET_TELEFONO';
export const SET_IS_MI_CBU = 'SET_IS_MI_CBU';
export const SET_BLOB_SOL_FIRMADA = 'SET_BLOB_SOL_FIRMADA';
export const RESET_VALUES_AYUDA_ECNOMICA = 'RESET_VALUES_AYUDA_ECNOMICA';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* NOTIFICACIONES */
export const FETCH_NOTIF_REQUEST = 'FETCH_NOTIF_REQUEST';
export const FETCH_NOTIF_SUCCESS = 'FETCH_NOTIF_SUCCESS';
export const FETCH_NOTIF_ERROR = 'FETCH_NOTIF_ERROR';
export const FETCH_NOTIF_MODAL_SUCCESS = 'FETCH_NOTIF_MODAL_SUCCESS';
export const FETCH_NOTIF_SLIDER_SUCCESS = 'FETCH_NOTIF_SLIDER_SUCCESS';
export const FETCH_NOTIF_HEADER_SUCCESS = 'FETCH_NOTIF_HEADER_SUCCESS';
export const SET_DETALLE_NOTIF = 'SET_DETALLE_NOTIF';
export const SET_NOTIF_LEIDA_REQUEST = 'SET_NOTIF_LEIDA_REQUEST';
export const SET_NOTIF_LEIDA_SUCCESS = 'SET_NOTIF_LEIDA_SUCCESS';
export const DELETE_NOTIF_REQUEST = 'DELETE_NOTIF_REQUEST';
export const DELETE_NOTIF_SUCCESS = 'DELETE_NOTIF_SUCCESS';

/* SERVCIOS SOCIO */
export const FETCH_SERVICIOS_REQUEST = 'FETCH_SERVICIOS_REQUEST';
export const FETCH_SERVICIOS_SUCCESS = 'FETCH_SERVICIOS_SUCCESS';
export const FETCH_SERVICIOS_ERROR = 'FETCH_SERVICIOS_ERROR';
export const SET_CONTRAIBLE_EXPANDED = 'SET_CONTRAIBLE_EXPANDED';
export const SET_CONTRAIBLE_CHILD_EXPANDED = 'SET_CONTRAIBLE_CHILD_EXPANDED';
export const SET_ACORDION_SERVICIO = 'SET_ACORDION_SERVICIO';
export const SET_TIPO_SERVICIO_TURNO_SEL = 'SET_TIPO_SERVICIO_TURNO_SEL';
export const SET_TIPO_SERVICIO_AYUDAS = 'SET_TIPO_SERVICIO_AYUDAS';

/*DIGITAL SIGNATURE */
export const SET_START_SIGNATURE = 'SET_START_SIGNATURE';
export const SET_FINISH_SIGNATURE = 'SET_FINISH_SIGNATURE';
export const SET_IS_FULFILL_SIGNATURE = 'SET_IS_FULFILL_SIGNATURE';
export const SET_IMAGE_DATA = 'SET_IMAGE_DATA';
