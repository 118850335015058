import axios from 'axios';

function fetch(service, params) {
  return axios.get(`/api/${service}/${params}`, {
    headers: {},
  });
}

const isValidCbu = (cuil, cbu) => {
  const params = `${cuil}/${cbu}`;
  const service = 'agent/validar_cbu';
  return fetch(service, params);
};

export const agent = {
  fetch,
  isValidCbu,
};
export default agent;
