export const appConfig = {
  version: '1.71',
  // apiUrl: 'http://192.168.0.27:8080',
  // apiUrl: 'https://test-api.app.ampf.org.ar',
  apiUrl: 'https://api.app.ampf.org.ar',
  ambienteMp: 'Prod', // prod o Sandbox
  // mpRedirectVal: 'Prod'
};

export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [{ id: 'es', name: 'Español', direction: 'ltr' }];

export const searchPath = '#';
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.redruby';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const colorsCardsAEenTramite = {
  PARA_PAGO: 'rgb(215, 255, 252)',
  REQ_FIRMA: 'rgb(206, 203, 202)',
  ANULADA: 'rgb(206, 203, 202)',
};

export const colorsLabelAEenTramite = {
  PARA_PAGO: 'rgb(5, 13, 158)',
  REQ_FIRMA: 'rgb(46, 153, 65)',
  ANULADA: 'rgb(194, 0, 47)',
};

export const estadoSituacionAEenTramite = {
  paraPago: 'PARA_PAGO',
  reqFirma: 'REQ_FIRMA',
  anulada: 'ANULADA',
};

export const colorsLabelSolMargen = {
  VERDE: 'rgb(5, 13, 158)',
  ROJO: 'rgb(194, 0, 47)',
  AZUL: 'blue',
};

// Constantes para el modulo de SALUD
export const tipoAtencionTurno = {
  PRESENCIAL: 'NORM',
  VIRTUAL: 'VIRT',
  PRESENCIALVIRTUAL: 'PRVI',
  TELEFONO: 'TELE',
  TELEFONICO: 'TELF',
  TELEFONICOVIRTUAL: 'TEVI',
  PRESENCIALVIRTUALTELEFONICO: 'TVPR',
};

// Constantes para el modulo de SS Cursos
export const modalidadCursos = {
  PRESENCIAL: 'PRE',
  VIRTUAL: 'VIR',
  AUTOGESTIONADO: 'AUT',
  ZOOM: 'ZOM',
};

/* Tipos de servicio para turnos. */
export const tipoServicioTurnos = {
  SERVICIO_SALUD: 'SA',
  SERVICIO_SOCIAL: 'SS',
};
/* Tipos de servicio para Ayudas. */
export const tipoServicioEconomico = {
  AYUDAS_ECONOMICAS: 'AYUDAS_ECONOMICAS',
};

// Formato numerico para moneda local
export const numberLocalConf = {
  locales: 'es-AR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'ARS',
};

// Tipo de modo para msgBox
export const msgBoxModeType = {
  STYLE_CONFIRMATION: 'STYLE_CONFIRMATION',
  CONFIRMATION: 'CONFIRMATION',
  INFORMATION: 'INFORMATION',
  WARNING: 'WARNING',
};

// Tiempo para los mensajes de notificacion

export const timeOutMsBox = 6000;

// Identificador del Contraible
export const contraibleIdValues = {
  SALUD: 'SALUD',
  SALUD_MIS_TURNOS: 'SALUD_MIS_TURNOS',
  ECONOMICOS: 'ECONOMICOS',
  ECONOMICOS_AYUDAS: 'ECONOMICOS_AYUDAS',
  ECONOMICOS_AHORRO: 'ECONOMICOS_AHORRO',
  ECONOMICOS_CAJA_AHORRO: 'ECONOMICOS_CAJA_AHORRO',
  SOCIALES: 'SOCIALES',
  SOCIALES_MIS_TURNOS: 'SOCIALES_MIS_TURNOS',
};

export const iconSize = {
  XS: '1.2rem',
  S: '2rem',
  M: '3rem',
  L: '4.5rem',
  XL: '6rem',
  XXL: '8rem',
  XXXL: '10rem',
};

export const colorsPortal = {
  SALUD: '#cccc00',
  ECONOMICO: '#00cccc',
  SOCIAL: '#ff0000',
  TURNOS: '#514f4f',
  TURNOS_HOVER: '#A1A093',
  TURNOS_HOVER_MOB: '#514f4f',
  TURNOS_ACTIVE: '#8D8C83',
  SALUD_HOVER: '#b3b300',
  ECONOMICO_HOVER: '#00b3b3',
  SOCIAL_HOVER: '#b30000',
};

// relacion entre el codigo de los documentos y las mutuales.
export const idMutualToCodMutual = {
  '01': 'AMPF',
  '02': 'AMPC',
};

// Constantes para circuito olvidé mi clave
export const olvideMiClave = {
  SEGUNDOS_PARA_REENVIAR: '180',
  PORCENTAJE_DISPLAY: '10',
};
