import axios from 'axios';

export const osanaService = {
  asignarTurno,
  cancelarTurno,
};

function asignarTurno(data) {
  return axios.post('/api/osana/turno/asignacion', data);
}

function cancelarTurno(data) {
  return axios.post('/api/osana/turno/cancelacion', data);
}

export default osanaService;
