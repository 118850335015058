import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { persistence } from '../../services';
import { getErrorOject } from '../../helpers/fetch-error';

import {
  GET_GRUPO_AYUDA_REQUEST,
  FETCH_AGREGAR_AYUDA_REQUEST,
  FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST,
} from '../actions';

import {
  fecthGrupoAyudaSuccess,
  fectchGrupoAyudaError,
  fetchAgregarAyudaSuccess,
  fetchAgregarAyudaError,
} from './actions';

const getGrupoAyudaAsync = async (
  codMutual: string,
  cuil: string,
  solMargenId: string
) =>
  await persistence
    .fetch('ayudas_economicas', 'get_grupo_ayudas', {
      cuil,
      cod_mutual: codMutual,
      sol_margen_id: solMargenId,
    })
    .then((serv) => serv)
    .catch((error) => getErrorOject(error));

function* getGrupoAyuda(action: any): any {
  const { codMutual, cuil, solMargenId } = action.payload;
  try {
    const grupoAyuda = yield call(
      getGrupoAyudaAsync,
      codMutual,
      cuil,
      solMargenId
    );
    if (!grupoAyuda.errorMessage) {
      yield put(fecthGrupoAyudaSuccess(grupoAyuda));
    } else {
      yield put(fectchGrupoAyudaError(grupoAyuda.errorMessage));
    }
  } catch (error) {
    yield put(fectchGrupoAyudaError(error));
  }
}

const fetchAgregarAyudaAsync = async (params: any) =>
  await persistence
    .fetch('ayudas_economicas', 'agregar_ayuda_g1', {
      cod_mutual_ori: params.COD_MUTUAL_AE_ORI,
      cod_delegacion_ori: params.COD_DELEGACION_AE_ORI,
      nro_solicitud_ori: params.NRO_SOLICITUD_AE_ORI,
      cod_proveedor_financiero: params.COD_PROVEEDOR_FINANCIERO,
      cod_proveedor_venta: params.COD_PROVEEDOR_VENTA,
      cod_linea_credito: params.COD_LINEA_CREDITO,
      cod_linea_venta: params.COD_LINEA_VENTA,
      cuotas: params.CUOTAS,
      valor_cuota_proveedor: params.VALOR_CUOTA_PROVEEDOR,
      valor_cuota: params.VALOR_CUOTA,
      quebranto: params.QUEBRANTO,
      sellados: params.SELLADOS,
      gastos: params.GASTOS,
      gastos_proveedor: params.GASTOS_PROVEEDOR,
      intereses: params.INTERESES,
      monto: params.MONTO,
      neto: params.NETO,
      neto_final: params.NETO_FINAL,
      cod_tipo_ayuda: params.cod_tipo_ayuda,
      margen: params.MARGEN,
      valor_cuota_2do_vto: params.VALOR_CUOTA_2DO_VTO,
      fecha_inicio_descuento: params.FECHA_INICIO_DESCUENTO,
      fecha_2do_vto: params.FECHA_2DO_VTO,
      periodo_inicio_descuento: params.PERIODO_INICIO_DESCUENTO,
      cod_forma_pago: params.COD_FORMA_PAGO,
      gastos_cuota: params.GASTOS_CUOTA,
      ino_id: params.INO_ID,
      color_scoring: params.COLOR_SCORING,
      cod_sit_scr: params.COD_SIT_SCR,
      cel_contacto: params.cel_contacto,
      sol_margen_id: params.SOL_MARGEN_ID,
    })
    .then((serv) => serv)
    .catch((error) => getErrorOject(error));

function* fetchAgregarAyuda(action: any): any {
  try {
    const nuevaAyuda = yield call(fetchAgregarAyudaAsync, action.payload);
    if (!nuevaAyuda.errorMessage) {
      yield put(fetchAgregarAyudaSuccess(nuevaAyuda));
    } else {
      yield put(fetchAgregarAyudaError(nuevaAyuda.errorMessage));
    }
  } catch (error) {
    yield put(fetchAgregarAyudaError(error));
  }
}

const fetchConsultarAyudaFirmadaAsync = async (params: any) =>
  await persistence
    .fetch('ayudas_economicas', 'get_info_ayuda', {
      cod_mutual: params.cod_mutual,
      cod_delegacion: params.cod_delegacion,
      nro_solicitud: params.nro_solicitud,
    })
    .then((serv) => serv)
    .catch((error) => getErrorOject(error));

function* fetchConsultarAyudaFirmada(action: any): any {
  try {
    const ayudaConsultada = yield call(
      fetchConsultarAyudaFirmadaAsync,
      action.payload
    );
    if (!ayudaConsultada.errorMessage) {
      yield put(fetchAgregarAyudaSuccess(ayudaConsultada));
    } else {
      yield put(fetchAgregarAyudaError(ayudaConsultada.errorMessage));
    }
  } catch (error) {
    yield put(fetchAgregarAyudaError(error));
  }
}

export function* watchGetGrupoAyuda() {
  yield takeEvery(GET_GRUPO_AYUDA_REQUEST, getGrupoAyuda);
}

export function* watchFetchAgregarAyuda() {
  yield takeEvery(FETCH_AGREGAR_AYUDA_REQUEST, fetchAgregarAyuda);
}

export function* watchFetchConsultarAyudaFirmada() {
  yield takeEvery(
    FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST,
    fetchConsultarAyudaFirmada
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetGrupoAyuda),
    fork(watchFetchAgregarAyuda),
    fork(watchFetchConsultarAyudaFirmada),
  ]);
}
