import axios from 'axios';

export const persistence = {
  fetch,
};

function fetch(_package, sp, params, _public = false) {
  return axios.get(`/api/${_public ? 'public/' : ''}${_package}/${sp}`, {
    params,
  });
}
export default persistence;
