import {
  GET_GRUPO_AYUDA_REQUEST,
  FETCH_GRUPO_AYUDA_SUCCESS,
  FETCH_GRUPO_AYUDA_ERROR,
  FETCH_AGREGAR_AYUDA_REQUEST,
  FETCH_AGREGAR_AYUDA_SUCCESS,
  FETCH_AGREGAR_AYUDA_ERROR,
  SET_MONTO_SOLICITADO,
  SET_SOL_MARGEN_ID,
  SET_OBS_DOCUMENTOS,
  SET_PLAN_SELECTED,
  SET_IS_STEP_VALIDO,
  SET_IS_VALIDAR_STEP,
  SET_TELEFONO,
  SET_IS_MI_CBU,
  SET_BLOB_SOL_FIRMADA,
  RESET_VALUES_AYUDA_ECNOMICA,
} from '../actions';

const INIT_STATE = {
  dataGrupoAyuda: {},
  planSelected: {},
  dataNuevaAyuda: {},
  isFetchingAgregarAyuda: false,
  error: null,
  montoSolicitado: 0,
  solMargenId: '',
  obsDocumentos: '',
  isStepValido: false,
  isValidarStep: false,
  telefono: '',
  isMiCBU: false,
  blobSolFirmada: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GRUPO_AYUDA_REQUEST:
      return {
        ...state,
        error: null,
      };

    case FETCH_GRUPO_AYUDA_SUCCESS:
      return {
        ...state,
        dataGrupoAyuda: action.payload.data[0],
        error: null,
        ultStepValido: 0,
        stepAValidar: 0,
      };

    case FETCH_GRUPO_AYUDA_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_AGREGAR_AYUDA_REQUEST:
      return {
        ...state,
        isFetchingAgregarAyuda: true,
        error: null,
      };

    case FETCH_AGREGAR_AYUDA_SUCCESS:
      return {
        ...state,
        isFetchingAgregarAyuda: false,
        dataNuevaAyuda: action.payload.data[0],
        error: null,
      };

    case FETCH_AGREGAR_AYUDA_ERROR:
      return {
        ...state,
        isFetchingAgregarAyuda: false,
        error: action.payload,
      };

    case SET_MONTO_SOLICITADO:
      // eslint-disable-next-line no-case-declarations
      const plan = action.payload ? state.planSelected : {};
      return {
        ...state,
        montoSolicitado: action.payload,
        planSelected: plan,
      };

    case SET_SOL_MARGEN_ID:
      return {
        ...state,
        solMargenId: action.payload,
      };

    case SET_OBS_DOCUMENTOS:
      return {
        ...state,
        obsDocumentos: action.payload,
      };

    case SET_PLAN_SELECTED:
      return {
        ...state,
        planSelected: action.payload,
      };
    case SET_IS_STEP_VALIDO:
      return {
        ...state,
        isStepValido: action.payload,
      };

    case SET_IS_VALIDAR_STEP:
      return {
        ...state,
        isValidarStep: action.payload,
      };

    case SET_TELEFONO:
      return {
        ...state,
        telefono: action.payload,
      };

    case SET_IS_MI_CBU:
      return {
        ...state,
        isMiCBU: action.payload,
      };

    case SET_BLOB_SOL_FIRMADA: {
      const { blobSolFirmada, folder, fileName } = action.payload;
      return {
        ...state,
        blobSolFirmada: blobSolFirmada,
        folder: folder,
        fileName: fileName,
      };
    }
    case RESET_VALUES_AYUDA_ECNOMICA:
      return {
        ...state,
        ...INIT_STATE,
      };

    default:
      return state;
  }
};
