import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { persistence } from '../../services';
import { getErrorOject } from '../../helpers/fetch-error';

import {
  FETCH_NOTIF_REQUEST,
  DELETE_NOTIF_REQUEST,
  SET_NOTIF_LEIDA_REQUEST,
} from '../actions';

import {
  fecthNotifSuccess,
  fectchNotifError,
  deleteNotifSuccess,
  setNotifLeidaSuccess,
} from './actions';

export function* watchFetchNotifRequest() {
  yield takeEvery(FETCH_NOTIF_REQUEST, getNotif);
}

const getNotifAsync = (codMutual, cuil) =>
  persistence
    .fetch(
      'notificaciones',
      'get_notificaciones',
      { cuil, cod_mutual: codMutual },
      false
    )
    .then((notif) => notif)
    .catch((error) => getErrorOject(error));

function* getNotif({ payload }) {
  const { codMutual, cuil } = payload;
  try {
    const notificaciones = yield call(getNotifAsync, codMutual, cuil);
    if (!notificaciones.errorMessage) {
      yield put(fecthNotifSuccess(notificaciones));
    } else {
      yield put(fectchNotifError(notificaciones.errorMessage));
    }
  } catch (error) {
    yield put(fectchNotifError(error.message));
  }
}

export function* watchDeleteNotifRequest() {
  yield takeEvery(DELETE_NOTIF_REQUEST, deleteNotif);
}

const deleteNotifAsync = async (notifId, codMutual, cuil) =>
  await persistence
    .fetch(
      'notificaciones',
      'eliminar_notificacion',
      { notif_id: notifId, cuil, cod_mutual: codMutual },
      false
    )
    .then((notif) => notif)
    .catch((error) => error);

function* deleteNotif({ payload }) {
  const { notifId, codMutual, cuil } = payload;
  try {
    const notificaciones = yield call(
      deleteNotifAsync,
      notifId,
      codMutual,
      cuil
    );
    if (!notificaciones.message) {
      yield put(deleteNotifSuccess(notificaciones));
    } else {
      yield put(fectchNotifError(notificaciones.message));
    }
  } catch (error) {
    yield put(fectchNotifError(error.message));
  }
}

export function* watchSetNotifLeidaRequest() {
  yield takeEvery(SET_NOTIF_LEIDA_REQUEST, setNotifLeida);
}

const setNotifLeidaAsync = async (notifId, codMutual, cuil) =>
  await persistence
    .fetch(
      'notificaciones',
      'marcar_leidas',
      { notif_id: notifId, cuil, cod_mutual: codMutual },
      false
    )
    .then((notif) => notif)
    .catch((error) => error);

function* setNotifLeida({ payload }) {
  const { notifId, codMutual, cuil } = payload;
  try {
    const notificaciones = yield call(
      setNotifLeidaAsync,
      notifId,
      codMutual,
      cuil
    );
    if (!notificaciones.message) {
      yield put(setNotifLeidaSuccess(notificaciones));
    } else {
      yield put(fectchNotifError(notificaciones.message));
    }
  } catch (error) {
    yield put(fectchNotifError(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchNotifRequest),
    fork(watchDeleteNotifRequest),
    fork(watchSetNotifLeidaRequest),
  ]);
}
