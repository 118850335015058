import {
  SET_START_SIGNATURE,
  SET_FINISH_SIGNATURE,
  SET_IS_FULFILL_SIGNATURE,
  SET_IMAGE_DATA,
} from '../actions';

const INIT_STATE = {
  isSignatureLoading: false,
  isFulfillSignature: false,
  imageData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_START_SIGNATURE:
      return {
        ...state,
        isSignatureLoading: true,
      };

    case SET_FINISH_SIGNATURE:
      return {
        ...state,
        isSignatureLoading: false,
      };

    case SET_IS_FULFILL_SIGNATURE:
      return {
        ...state,
        isFulfillSignature: action.payload,
      };

    case SET_IMAGE_DATA:
      return {
        ...state,
        imageData: action.payload,
      };

    default:
      return state;
  }
};
