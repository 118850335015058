import {
  GET_GRUPO_AYUDA_REQUEST,
  FETCH_GRUPO_AYUDA_SUCCESS,
  FETCH_GRUPO_AYUDA_ERROR,
  FETCH_AGREGAR_AYUDA_REQUEST,
  FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST,
  FETCH_AGREGAR_AYUDA_SUCCESS,
  FETCH_AGREGAR_AYUDA_ERROR,
  SET_MONTO_SOLICITADO,
  SET_SOL_MARGEN_ID,
  SET_OBS_DOCUMENTOS,
  SET_PLAN_SELECTED,
  SET_IS_STEP_VALIDO,
  SET_IS_VALIDAR_STEP,
  SET_TELEFONO,
  SET_IS_MI_CBU,
  SET_BLOB_SOL_FIRMADA,
  RESET_VALUES_AYUDA_ECNOMICA,
} from '../actions';

export const GetGrupoAyudaRequest = (codMutual, cuil, solMargenId) => ({
  type: GET_GRUPO_AYUDA_REQUEST,
  payload: { codMutual, cuil, solMargenId },
});
export const fecthGrupoAyudaSuccess = (payload) => ({
  type: FETCH_GRUPO_AYUDA_SUCCESS,
  payload: payload.data,
});
export const fectchGrupoAyudaError = (payload) => ({
  type: FETCH_GRUPO_AYUDA_ERROR,
  payload,
});
export const fetchAgregarAyudaRequest = (params) => ({
  type: FETCH_AGREGAR_AYUDA_REQUEST,
  payload: params,
});
export const fetchConsultarAyudaFirmadaRequest = (params) => ({
  type: FETCH_CONSULTAR_AYUDA_FIRMADA_REQUEST,
  payload: params,
});
export const fetchAgregarAyudaSuccess = (payload) => ({
  type: FETCH_AGREGAR_AYUDA_SUCCESS,
  payload: payload.data,
});
export const fetchAgregarAyudaError = (payload) => ({
  type: FETCH_AGREGAR_AYUDA_ERROR,
  payload,
});
export const setMontoSolicitado = (montoSolicitado) => ({
  type: SET_MONTO_SOLICITADO,
  payload: montoSolicitado,
});
export const setSolMargenId = (payload) => ({
  type: SET_SOL_MARGEN_ID,
  payload,
});
export const setObsDocumentos = (payload) => ({
  type: SET_OBS_DOCUMENTOS,
  payload,
});
export const setPlanSelected = (payload) => ({
  type: SET_PLAN_SELECTED,
  payload,
});
export const setIsStepValido = (isValid) => ({
  type: SET_IS_STEP_VALIDO,
  payload: isValid,
});

export const setIsValidarStep = (isValid) => ({
  type: SET_IS_VALIDAR_STEP,
  payload: isValid,
});

export const resetValuesAyudaEconomica = () => ({
  type: RESET_VALUES_AYUDA_ECNOMICA,
});

export const setTelefono = (payload) => ({
  type: SET_TELEFONO,
  payload,
});

export const setIsMiCBU = (payload) => ({
  type: SET_IS_MI_CBU,
  payload,
});

export const setBlobSolFirmada = (payload) => ({
  type: SET_BLOB_SOL_FIRMADA,
  payload,
});
