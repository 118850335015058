import { isLocalhost } from 'siam-utils';
import { appConfig } from '../constants/defaultValues';

export const startPusher = (idPusher) => {
  try {
    if (!isLocalhost) {
      import('@pusher/push-notifications-web').then(
        (PusherPushNotifications) => {
          const beamsClient = new PusherPushNotifications.Client({
            instanceId: appConfig.instancePusher,
          });

          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: appConfig.urlPusher,
            queryParams: {
              id: idPusher,
            },
          });

          beamsClient
            .start()
            .then(() => beamsClient.addDeviceInterest('AMPF'))
            .then(() => beamsClient.getDeviceInterests())
            .then((interests) => console.log('Current interests:', interests))
            .catch(console.error)
            .then(() => beamsClient.setUserId(idPusher, beamsTokenProvider))
            .catch(console.error)
            .then(() => beamsClient.getDeviceId())
            .then((deviceId) =>
              console.log(
                'Successfully registered with Beams. Device ID:',
                deviceId
              )
            );
        }
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const stopPusher = () => {
  try {
    if (!isLocalhost) {
      import('@pusher/push-notifications-web').then(
        (PusherPushNotifications) => {
          const beamsClient = new PusherPushNotifications.Client({
            instanceId: appConfig.instancePusher,
          });

          beamsClient
            .clearDeviceInterests()
            .then(() => console.log('Device interests have been cleared'))
            .catch((e) => console.error('Could not clear device interests', e));

          beamsClient
            .stop()
            .then(() => console.log('Beams SDK has been stopped'))
            .catch((e) => console.error('Could not stop Beams SDK', e));
        }
      );
    }
  } catch (error) {
    undefined;
  }
};
