import axios from 'axios';

function identificar(data) {
  return axios.post('/auth/identify', data);
}

function registrar(data) {
  return axios.post('/auth/register/asociado', data);
}

function validarSms(data) {
  return axios.post('/auth/register/validar_sms', data);
}

function identificarRecuperoClave(data) {
  return axios.post('/auth/identify_password', data);
}

function validarCuenta(data) {
  return axios.post('/auth/validate_account', data);
}

function validarSmsClave(data) {
  return axios.post('/auth/register/validar_sms_password', data);
}

function validarMailClave(data) {
  return axios.post('/auth/register/validar_mail_password', data);
}

function actualizarClave(data) {
  return axios.post('/auth/update_password', data);
}

function usuariosValidar(data) {
  return axios.get('/auth/usuarios_a_validar', data);
}
function validarEmail(data) {
  return axios.post('/auth/validar_email', data);
}
function validarMobile(data) {
  return axios.post('/auth/validar_mobile', data);
}
function eliminarCuenta(data) {
  return axios.delete(`/api/user/${data.userId}`, { params: data });
}
function actualizarMedios(data) {
  return axios.post('/api/user/actualizar_medios', data);
}
function cambiarCelular(data) {
  return axios.post('/api/auth/cambiar_celular', data);
}
function cambiarEmail(data) {
  return axios.post('/api/auth/cambiar_email', data);
}

export const asociadoService = {
  identificar,
  registrar,
  validarSms,
  identificarRecuperoClave,
  validarCuenta,
  validarSmsClave,
  validarMailClave,
  actualizarClave,
  usuariosValidar,
  validarEmail,
  validarMobile,
  actualizarMedios,
  eliminarCuenta,
  cambiarCelular,
  cambiarEmail,
};

export default asociadoService;
