import {
  FETCH_NOTIF_REQUEST,
  FETCH_NOTIF_SUCCESS,
  FETCH_NOTIF_ERROR,
  FETCH_NOTIF_MODAL_SUCCESS,
  FETCH_NOTIF_SLIDER_SUCCESS,
  FETCH_NOTIF_HEADER_SUCCESS,
  SET_DETALLE_NOTIF,
  SET_NOTIF_LEIDA_REQUEST,
  SET_NOTIF_LEIDA_SUCCESS,
  DELETE_NOTIF_REQUEST,
  DELETE_NOTIF_SUCCESS,
} from '../actions';

const INIT_STATE = {
  modal: [],
  slider: [],
  header: [],
  detalleNotifSel: {},
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_NOTIF_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_NOTIF_SUCCESS:
      return {
        ...state,
        loading: false,
        header: action.payload.header,
        modal: action.payload.modal,
        slider: action.payload.slider,
      };

    case FETCH_NOTIF_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        modal: action.payload.data,
      };

    case FETCH_NOTIF_SLIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        slider: action.payload.data,
      };

    case FETCH_NOTIF_HEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        header: action.payload.data,
      };

    case FETCH_NOTIF_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case SET_DETALLE_NOTIF:
      return {
        ...state,
        detalleNotifSel: action.payload,
      };

    case SET_NOTIF_LEIDA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SET_NOTIF_LEIDA_SUCCESS:
      return {
        ...state,
        loading: false,
        header: action.payload.data,
      };

    case DELETE_NOTIF_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DELETE_NOTIF_SUCCESS:
      return {
        ...state,
        loading: false,
        header: action.payload.data,
      };

    default:
      return state;
  }
};
