import { NotificationManager } from '../components/common/react-notifications';

export const manageError = (error) => {
  const { status, data } = error.response || {};

  if (status === 435) {
    window.location.href = '/user/token-expired';
  } else {
    const { message = 'Error de Red inesperado, intenta mas tarde.' } =
      data || {};
    NotificationManager.error(message, 'Error');
  }
};

export const getErrorMessage = (error, defaultTypeMessage) => {
  const { status, data } = error.response || {};
  const defaultMessages = {
    mail: 'Tuvimos problemas en enviar el mail. Intenta mas tarde, o comunicate por telefono',
  };

  if (status === 435) {
    window.location.href = '/user/token-expired';
  } else {
    const { message = 'Error de Red inesperado, intenta mas tarde.' } =
      data || {};
    return defaultMessages[defaultTypeMessage] || message;
  }
};

export const getErrorOject = (error, defaultTypeMessage) => {
  return { errorMessage: getErrorMessage(error, defaultTypeMessage) };
};
